.main {
  margin: 0 auto;
  padding: 0px;
  display: block;
}

.container-login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  background-color: blue;
}
.wrap-login {
  width: 100%;
  background-color: aqua;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row-reverse;
}

.login-form {
  width: 560px;
  min-height: 100vh;
  display: block;
  background-color: white;
  padding: 173px 55px 55px 55px;
}

.login-form-title {
  display: block;
  font-family: Poppins-Regular;
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
}

.wrap-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 80px;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 10px;
}

input {
  outline: none;
  border: none;
}

.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  padding: 0 26px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid #6675df;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scaleX(1.1) scaleY(1.3);
  -moz-transform: scaleX(1.1) scaleY(1.3);
  -ms-transform: scaleX(1.1) scaleY(1.3);
  -o-transform: scaleX(1.1) scaleY(1.3);
  transform: scaleX(1.1) scaleY(1.3);
}

.label-input100 {
  font-family: Montserrat-Regular;
  font-size: 18px;
  color: #999999;
  line-height: 1.2;
  display: block;
  position: absolute;
  pointer-events: none;
  width: 100%;
  padding-left: 24px;
  left: 0;
  top: 30px;
}

.flex-sb-m {
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
}

p-b-32 {
  padding-bottom: 32px;
}

p-t-3 {
  padding-top: 3px;
}

.label-checkbox100 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #999999;
  line-height: 1.4;
  position: relative;
  padding-left: 5px;
  cursor: pointer;
  margin: 0;
}

.txt1 {
  font-family: Montserrat-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #555555;
  text-decoration: none;
  background-color: transparent;
}

.container-login100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: #6675df;
  font-family: Montserrat-Bold;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  outline: none !important;
  border: none;
}

.txt2 {
  font-family: Montserrat-Regular;
  font-size: 13px;
  line-height: 1.4;
  color: #999999;
}

.flex-c-m {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.login100-form-social-item {
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #fff;
  border-radius: 50%;
}

.flex-c-m {
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.bg1 {
  background: #3b5998;
}

element.style {
}
.login100-form-social-item {
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #fff;
  border-radius: 50%;
}
.bg1 {
  background: #3b5998;
}
.flex-c-m {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}
.m-r-5 {
  margin-right: 5px;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg2 {
  background: #1da1f2;
}
